/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import Container from "../../components/container";
import Select from "../../components/Select";
import Slider from "../../components/MainAdminSlider";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import InfoImg from "../../assets/icons/information.svg";
import { createErrorAlert, getCatalogue, getDegrees, getSpecificStudyModules } from "../../redux/actions";
import { catalogueData, degreesData, studyModulesData } from "../../redux/selectors";
import { Helmet } from "react-helmet";
import StudyCard from "../../components/StudyCard";
import _ from "lodash";
import InfoBox from "../../components/InfoBox";
import Button from "../../components/EmptyButton";
import { apiRequest, getUser } from "../../helpers";
import findImg from "../../assets/newImages/Group 1406.png"
import { FaArrowRight } from "react-icons/fa";
import bgIMG from "../../"
import Context from "../../services/Context";
const MainAdminPage = (props) => {
  const history = useHistory();
  const user = JSON.parse(getUser())
  const dispatch = useDispatch()

  const { studyModulesData, getStudyModules, dataCatalogue, getCatalogue } = props;
  const [showInfo, setShowInfo] = useState(false);
  const [module, setModule] = useState([]);
  const [recom, setRecom] = useState([]);
  const [paidModules, setPaidModules] = useState([])

  const {value} = useContext(Context)
  const userCurrency = value?.userCurrency || user?.user_currency

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    getStudyModules();
    getCatalogue(null);
  }, []);
  useEffect(() => {
    for (let i = 0; i < dataCatalogue?.data?.length; i++) {
      if (dataCatalogue?.data[i]?.is_paid === true) {
        if (
          !paidModules?.includes(
            dataCatalogue?.data[i]?.study_module?.slug
          )
        ) {
          paidModules?.push(dataCatalogue?.data[i]?.study_module.slug);
        }
      }
    }
  }, [dataCatalogue]);
  useEffect(() => {
    if (sessionStorage.getItem("stripRedirect")) {
      sessionStorage.removeItem("stripRedirect");
    }
    if (
      localStorage.getItem("events") &&
      !window.location.href.includes("appointments_create")
    ) {
      localStorage.removeItem("events");
    }
    if (studyModulesData) {
      if (studyModulesData.length > 0) {
        let recom = [];
        studyModulesData.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            recom.push({
              value: item,
              label: item.title,
            });
          }
        });
        // eslint-disable-next-line no-undef
        let sortedd = _.sortBy(recom, "label");
        setModule(sortedd);
        let arr = [];
        let filter = recom.slice(0, 10);
        filter.forEach((e) => {
          if (
            e.value.slug !== "macroeconomics" &&
            e.value.slug !== "microeconomics" &&
            e.value.slug !== "business" &&
            e.value.slug !== "marketing" &&
            e.value.slug !== "our-company" &&
            e.value.slug !== "useful-articles" &&
            e.value.slug !== "uncategorized" &&
            e.value.slug !== "test" &&
            e.value.slug !== "partners"
          ) {
            arr.push(e);
            arr.sort();
          }
        });
        // eslint-disable-next-line no-undef
        let sortedTen = _.sortBy(arr, "label");
        for (let i = 0; i < sortedTen.length; i++) {
          sortedData.push(sortedTen[i]);
        }
        setRecom(filter);
      }
    }
  }, [studyModulesData]);

  const slugName = (selected) => {
    if (selected.value) {
      localStorage.setItem("study-module", selected.value.slug);
    } else {
      localStorage.setItem("study-module", selected.slug);
    }
    history.push({
      pathname: `/study-module/${selected.value.slug}`,
      state: { selected },
    });
  };

  const handleSelectedModule = (selected) => {
    localStorage.setItem("study-module", selected.value.slug);
    history.push({
      pathname: `/study-module/${selected.value.slug}`,
      state: { selected },
    });
  };

  const addToBasket = async (item) => {
    let obj = {
      study_module: item.value.id
    }
    await apiRequest({
      method: 'post',
      url: "content/basket/",
      data: obj,
    }).then((res) => {
      if(res.status === 201){
        history.push('/shopping-basket')
      }
    }).catch((err) => {
      dispatch(createErrorAlert(400, "", err.response.data[0], 400))
    })
  }

  return (
    <div className="bg-white bg-main-admin-back bg-no-repeat">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Study Modules</title>
        <meta name="description" content="Study Modules Description" />
        <link rel="canonical" href="https://simplestudying.com/" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Study Modules" />
        <meta property="og:description" content="Study Modules Description" />
        <meta property="og:url" content="https://simplestudying.com/" />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <Container>
        <div className="flex flex-col md:flex-row mb-5">
          <div className="flex-1" style={{flex:"40%",flexBasis:"40%"}}>
          <div className="py-8 flex items-center custom:pt-16 custom:pb-11">
          <div className=" flex items-center relative">
            <img
              src={InfoImg}
              alt="info-img"
              className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(false)}
            />
            {showInfo && (
              <div className=" tooltipSSec absolute z-10 top-10 h-1080">
                <InfoBox text="Please choose the study module from the list below or type the name of the study module in the search bar to search faster e.g. land law. Our team of successful law graduates from different universities of England recently were in your position and achieved high 2.1 or First Class in their respective law degrees. We summarised and simplified the overcomplicated information for you from our experience and focused on what you need to know to get a First Class. We created simple notes with exam tips, case summaries, sample essays, tutorial videos, quizzes and flashcards all specifically designed for you to get a First Class in the simplest way possible. " />
              </div>
            )}
            <p className="font-bold text-14 sm:text-14 md:text-h4 custom:text-h3 ">
              Choose Study Module below:
            </p>
          </div>
          {/* {user?.subscription_name === 'Basic Subscription' && user?.free_trial === false && <div className="hidden custom:block flex-grow">
             <div className="custom:w-64 xl:w-340 float-right -mt-10">
                 <Button filled onClick={() => history.push('/subscription')}>
                     <p className="custom:text-15 xl:text-body-large text-center px-5">Get access to all
                         study modules <br className="custom:hidden xl:block"/> for £7.5/month</p>
                 </Button>
             </div>
          </div>} */}
        </div>
        <div className="flex flex-col md:flex-row md:space-x-7">
          {/*<div className='md:w-56 lg:w-80'>*/}
          {/*    <Select*/}
          {/*        placeholder="University"*/}
          {/*        values={universitiesList}*/}
          {/*    />*/}
          {/*</div>*/}
          <div className="md:w-56 lg:w-80">
            <Select
              placeholder="Study Module"
              values={module}
              handleSelected={handleSelectedModule}
            />
          </div>
        </div>
        <div className="py-5">
          <p className="text-darkGrey text-14">
            Neither our company, nor our products are officially affiliated
            <br className="hidden md:block" /> with any of the universities
            listed{" "}
          </p>
          <br />
          {paidModules.length > 0 &&  <p className="text-darkGrey text-14">
            You can access the study modules you purchased at <span className="text-primary cursor-pointer" onClick={() => history.push('/library')}>Your Library</span>
          </p>}
        </div>
          </div>
          <div className="flex flex-1 mt-14 bg-white p-6 rounded-2xl" style={{flex:"50%",flexBasis:"50%"}}>
            <div className="flex-1 " style={{flex:"30%",flexBasis:"30%"}}>
              <img src={findImg}/>
            </div>
            <div className="flex-1" style={{flex:"70%",flexBasis:"70%"}}>
              <p className="text-primary fw-semibold mb-2">
              Can't find the study materials you're looking for here?
              </p>
              <ul className=" list-disc">
                <li>If you can't locate the notes or other study materials you need on our platform, or if you prefer them in a different style, don't worry! We've got you covered. </li>
              <li>
              Simply send us all your relevant lecture and reading materials, and we'll craft personalized study materials just for you, exactly how you want them, tailored to your needs.
              </li>
              </ul>
              <div className="w-full">
              <button onClick={() => history.push('/grab-detail')} className="
              flex items-center fw-semibold
              text-primary text-14 text-center m-auto p-2 mt-2 border-primary border-2 hover:text-white hover:bg-primary">
              Grab your tailor-made study materials now! <FaArrowRight />
              </button>
              </div>
            
            </div>

            
          </div>
        </div>
        
        <div className="pb-5 custom:pb-14">
          <p className="font-bold text-body-large custom:text-h4">
            Recommended study materials from SimpleStudying:
          </p>
        </div>




        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          <Slider
            responsive={{
              superLargeDesktop: {
                autoPlay: true,
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1920 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 3,
              },
              ipadPro: {
                breakpoint: { max: 1501, min: 1201 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 993, min: 1199 },
                items: 2,
              },
              tabletBelow: {
                breakpoint: { max: 991, min: 768 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 767, min: 575 },
                items: 1,
              },
            }}
          >
            {sortedData?.map((item, index) => {
              console.log(item, "itemmmm")
              return (
                <div class="flip-card" onClick={() => slugName(item)}>
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <StudyMaterials
                        key={index}
                        image={item?.value.image}
                        cardTitle={item?.label}
                        slug={item}
                        text={item?.value?.short_description}
                        handleClick={slugName}
                        price={`${userCurrency === "UK" && !paidModules.includes(item?.value?.slug) ? "£" : userCurrency === "US" && !paidModules.includes(item?.value?.slug) ? "$" : ''} ${paidModules.includes(item?.value?.slug) && item?.value?.price ? "Purchased" : !paidModules.includes(item?.value?.slug) && item?.value?.price ? item?.value?.price : ' 99.99'}`}
                      />
                    </div>
                    <div class="flip-card-back p-4 flex flex-col">
                      <p
                        className="font-bold text-lg"
                        style={{ fontSize: "22px" }}
                      >
                        {item.label}
                      </p>
                      <div className={`flex my-5 flex-wrap gap-3 flex-grow`}>
                        {item?.value?.notes !== 0 ? (
                          <p
                            className="py-1 px-2 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Revision Notes
                          </p>
                        ) : null}
                        {item?.value?.essays !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Essays
                          </p>
                        ) : null}
                        {item?.value?.summaries !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Case Summaries
                          </p>
                        ) : null}
                        {item?.value?.quiz !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Quiz
                          </p>
                        ) : null}
                        {item?.value?.flash_cards !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Flash Cards
                          </p>
                        ) : null}
                        {item.tutorial_videos !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Tutorial Videos
                          </p>
                        ) : null}
                      </div>
                      <div className="m-3 mt-auto flex-shrink-0">
                        <Button filled text={userCurrency === "UK" ? "Add to Basket" : "Add to Cart"} onClick={(e) =>{
                          e.stopPropagation()
                           addToBasket(item)
                           }}></Button>
                      </div>
                      <div
                        className="contentColor flex-grow mb-7"
                        dangerouslySetInnerHTML={{
                          __html: item?.value?.short_description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        {/* <div>
          <p className="text-body-large font-bold pb-5 custom:pb-12 md:text-h4">
            Buy study materials from your university students who got a{" "}
            <span className="text-greenGradient">1st Class:</span>
          </p>
        </div>
        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          <Slider
            responsive={{
              superLargeDesktop: {
                autoPlay: false,
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1919 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 4,
              },
              ipadPro: {
                breakpoint: { max: 1500, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 3,
              },
              tabletBelow: {
                breakpoint: { max: 767, min: 576 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 575, min: 320 },
                items: 1,
              },
            }}
          >
            {sortedData?.map((item, index) => {
              return (
                <div class="flip-card">
                  <StudyCard
                    image={item?.value?.image}
                    cardTitle={item?.label}
                    cardDescription={item?.value?.degree?.university?.name}
                    price={
                      item?.value?.price !== null ? item?.value?.price : "£ 99.99"
                    }
                    classNum="2.1 Class"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className=" xl:mt-24">
          <p className="text-body-large font-bold pb-5 custom:pb-12 md:text-h4">
            Buy study materials from your university students who got a{" "}
            <span className="text-greenGradient">2:1 Class:</span>
          </p>
        </div>
        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          <Slider
            responsive={{
              superLargeDesktop: {
                autoPlay: false,
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1919 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 4,
              },
              ipadPro: {
                breakpoint: { max: 1500, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 3,
              },
              tabletBelow: {
                breakpoint: { max: 767, min: 576 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 575, min: 320 },
                items: 1,
              },
            }}
          >
            {sortedData?.map((item, index) => {
              return (
                <div class="flip-card">
                  <StudyCard
                    image={item?.value?.image}
                    cardTitle={item?.label}
                    cardDescription={item?.value?.degree?.university?.name}
                    price={
                      item?.value?.price !== null ? item?.value?.price : "£ 99.99"
                    }
                    classNum="2.1 Class"
                  />
                </div>
              );
            })}
          </Slider>
        </div> */}
      </Container>
      <div className="md:pb-12 bg-white  bg-subscription-bottom bg-no-repeat bg-bottom px-">
        <Container>
          {user?.subscription_name === 'Basic Subscription' && user?.free_trial === false && <div className="flex flex-col items-center text-center pt-16 ">
             <p className="font-bold text-h3">Get access to all the study <br/> modules</p>
             <div className="w-96 mt-12 lg:pb-20 pb-10 px-10 md:px-0">
                 <Button filled onClick={() => history.push('/subscription')}><p>BECOME A PREMIUM MEMBER</p></Button>
             </div>
          </div>}
          
        </Container>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  studyModulesData: studyModulesData(state),
  dataCatalogue: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStudyModules: () => dispatch(getSpecificStudyModules()),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainAdminPage);
