import React, { useContext, useEffect, useRef, useState } from "react";

import Container from "../../components/container";
import Stepper from "../../components/ContentCreatorStepper";
import UploadIcon from "../../assets/images/uploadIcon.png";
import Select from "../../components/Select";
import Input from "../../components/Input/InputForm";
import Button from "../../components/EmptyButton";
import RemoveIcon from "../../assets/images/removeIcon.webp";
import { useHistory } from "react-router-dom";
import { studyModulesData, userData } from "../../redux/selectors";
import { connect, useDispatch, useSelector } from "react-redux";
import { apiRequest, getUser } from "../../helpers";
import FullLoader from "../../components/Loader/FullLoader";
import { createErrorAlert, getUserInfo } from "../../redux/actions";
import convertTextToSlug from "../../helpers/textToSlug";
import Context from "../../services/Context";
const StepOne = ({ user_detail, getUserInfo }) => {
  const fileInputRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(getUser());

  const context = useContext(Context)

  const userCurrency = context?.value?.userCurrency || user?.user_currency
  const contentRegion = context?.value?.contentRegion

  const [state, setState] = useState({
    email: "",
    module: [],
    recom: [],
    sortedData: [],
    selectedModule: "",
    selectedUniversity: typeof user?.author === 'number' ? {label: user?.universities[0]?.name, value: convertTextToSlug(user?.universities[0]?.name)} : "",
    username: user.username,
    universities: [],
    selectedFile: typeof user?.author === 'number' ? {name: user?.proof_of_grade_file} : null,
    degrees: [],
    selectedDegree: typeof user?.author === 'number' ? {label: user?.degrees[0]?.name, value: convertTextToSlug(user?.degrees[0]?.name)} : "",
    selectedGrade: 1,
    loading: true,
    check: false,
    errorModule: false,
    errorUniversity: false,
    errorDegree: false
  });

  const studyModuleData = async () => {
    await apiRequest({
      method: "get",
      url: "studies/studymodules",
    }).then((res) => {
      let recom = [
        { value: "Add Study Module", label: "Add New Study Module" },
      ];
      res.data.map((item) => {
        if (
          item.slug !== "macroeconomics" &&
          item.slug !== "microeconomics" &&
          item.slug !== "business" &&
          item.slug !== "marketing" &&
          item.slug !== "our-company" &&
          item.slug !== "useful-articles" &&
          item.slug !== "uncategorized" &&
          item.slug !== "test" &&
          item.slug !== "partners"
        ) {
          if (item.author === user.author) {
            recom.push({
              value: item,
              label: item.title,
            });
          }
        }
      });
      // eslint-disable-next-line no-undef
      let sortedd = _.sortBy(recom, "label");
      setState((prevState) => ({
        ...prevState,
        module: sortedd,
        loading: false,
      }));
      let arr = [];
      let filter = recom.slice(0, 10);
      filter.forEach((e) => {
        if (
          e.value.slug !== "macroeconomics" &&
          e.value.slug !== "microeconomics" &&
          e.value.slug !== "business" &&
          e.value.slug !== "marketing" &&
          e.value.slug !== "our-company" &&
          e.value.slug !== "useful-articles" &&
          e.value.slug !== "uncategorized" &&
          e.value.slug !== "test" &&
          e.value.slug !== "partners"
        ) {
          arr.push(e);
          arr.sort();
        }
      });
      // eslint-disable-next-line no-undef
      let sortedTen = _.sortBy(arr, "label");
      for (let i = 0; i < sortedTen.length; i++) {
        setState((prevState) => ({
          ...prevState,
          sortedData: sortedTen[i],
          recom: filter,
        }));
      }
    });
  };

  const getUniversities = async () => {
    await apiRequest({
      method: "get",
      url: `studies/universities/`,
    }).then((res) => {
      let values = [];
      res.data.map((item) => {
        values.push({
          value: item,
          label: item.name,
        });
      });
      setState((prevState) => ({
        ...prevState,
        universities: values,
      }));
    });
  };

  const handleGetDegree = async () => {
    await apiRequest({
      method: "get",
      url: `studies/degrees/`,
    }).then((res) => {
      let values = [];
      res.data.map((item) => {
        values.push({
          value: item,
          label: item.name,
        });
      });
      setState((prevState) => ({
        ...prevState,
        degrees: values,
      }));
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setState((prevState) => ({
      ...prevState,
      selectedFile: selectedFile,
    }));
  };

  const handleButtonClick = () => {
    console.log("in click", fileInputRef);
    fileInputRef.current.click();
  };

  useEffect(() => {
    studyModuleData();
    getUniversities();
    handleGetDegree();
    localStorage.setItem(
      "degree-selected",
      JSON.stringify(user?.degrees[0])
    );
  }, []);

  const handleCreateContentCreator = async () => {
    if (state.module.length !== 0 && state.selectedModule === "") {
      setState((prev) => ({
        ...prev,
        errorModule: true,
      }));
      return;
    }
    if (state.universities.length !== 0 && state.selectedUniversity === "") {
      setState((prev) => ({
        ...prev,
        errorUniversity: true,
      }));
      return;
    }
    if (state.check === false) {
      dispatch(
        createErrorAlert(400, "", "Please agree to the Terms and Conditions", 400)
      );
      return;
    }
    if(state.degrees.length !== 0 && state.selectedDegree === ""){
      setState((prev) => ({
        ...prev,
        errorDegree: true,
      }));
      return;
    }
    if(state.selectedFile === null){
      dispatch(
        createErrorAlert(400, "", "Please upload your transcript of grades from university", 400)
      );
    }
    if (user.author === null || !user.author) {
      const formData = new FormData();
      formData.append("grade", state.selectedGrade);
      formData.append("university", state.selectedUniversity.value.id);
      if (state.selectedDegree !== "") {
        formData.append("degree", state.selectedDegree.value.id);
      }
      formData.append("proof_of_grade_file", state.selectedFile);
      formData.append("user", user.id);
      await apiRequest({
        method: "post",
        url: "accounts/content-creator/study-module-membership/",
        data: formData,
      }).then((res) => {
        getUserInfo();
        history.push("/content-creator/step-two");
      });
    } else if (user.author !== null && state.module.length === 0) {
      history.push("/content-creator/step-two");
    } else if (user.author !== null && state.module.length > 0) {
      history.push({
        pathname: "/content-creator/step-two",
        state: { module: state.selectedModule },
      });
    }
  };

  return (
    <div>
      {state.loading && <FullLoader />}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      <Stepper step={2} exit={() => history.push('/')} totalSteps={4}/>
        <Container>
          <div className="pt-10 lg:pt-14 flex">
            <div>
              <p className="custom2:text-36 font-bold">
                {user?.author === null || !user?.author
                  ? "Become a Content Creator"
                  : "Welcome Back"}
              </p>
            </div>
          </div>
          <div className="mt-50 text-body-large flex flex-col md:flex-row  md:space-x-20 lg:space-x-40 ">
            <form className="flex flex-col w-full md:w-2/4">
              {user?.author === null || !user?.author ? (
                <div className="xl:mb-9 lg:mb-7 sm:mb-5 mb-4">
                  <label htmlFor="subscription_email" className="mb-8">
                    Your username as a content creator
                  </label>
                  <Input
                    placeholder=""
                    value={state.username}
                    setValue={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        username: value,
                      }));
                    }}
                  />
                </div>
              ) : null}
              {state.module.length !== 0 && (
                <div>
                  <label className="mb-8 font-bold">Study Module:</label>
                  <p>Select 'Add New Study Module' if you are uploading new study materials.<br/>If you are editing study materials that you have already published, simply choose the study module you want to edit.</p>
                  <Select
                    placeholder={"Select Study Module"}
                    value={state.module.label}
                    values={state.module}
                    handleSelected={(value) => {
                      localStorage.setItem(
                        "content-module",
                        JSON.stringify(value.value)
                      );
                      setState((prevState) => ({
                        ...prevState,
                        selectedModule: value,
                        errorModule: false,
                      }));
                    }}
                  />
                  {state.errorModule && (
                    <p style={{ color: "red" }}>* This field is required</p>
                  )}
                </div>
              )}
              <div className="my-4">
                <label className="mb-8 font-bold">
                  Indicate university where you studied this study module
                </label>
                <Select
                  placeholder={"Select Study University"}
                  value={state.selectedUniversity}
                  values={state.universities}
                  handleSelected={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedUniversity: value,
                      errorUniversity: false,
                    }));
                  }}
                />
                {state.errorUniversity && (
                  <p style={{ color: "red" }}>* This field is required</p>
                )}
              </div>
              <div className="mt-4">
                <label className="mb-8 font-bold">Select the {userCurrency === "UK" ? "Degree" : "Major"}</label>
                <Select
                  placeholder={"Select Degree"}
                  value={state.selectedDegree}
                  values={state.degrees}
                  handleSelected={(value) => {
                    localStorage.setItem(
                      "degree-selected",
                      JSON.stringify(value.value)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      selectedDegree: value,
                    }));
                  }}
                />
                {state.errorDegree && (
                  <p style={{ color: "red" }}>* This field is required</p>
                )}
              </div>
            </form>
            <div className="flex flex-col w-full md:w-2/4">
              <p className="text-20 font-bold">
                What was your grade for the study module you want to sell?
              </p>
              <p className="text-15 text-darkGrey">
                Note: We only accept {userCurrency === "UK" ? "2.1 and First Class grades" : "A and B"}
              </p>
              <br/>
              <p><span className="text-20 font-bold">Example:</span> If you got a {userCurrency === "UK" ? "2.1" : "A or B"} in math, you can sell study materials for math. However, if you got a {userCurrency === "UK" ? "2.2" : "C"} in physics, you cannot sell study materials for physics. Therefore, your overall degree classification does not matter. We look at the grade you got in the particular module you want to sell on SimpleStudying.</p>
              <p className="text-body-large mt-30">Select:</p>
              <div className="contRadio">
                <div key={1} className="flex items-center mt-16">
                  <p className="flex items-center">
                    <input
                      id={1}
                      name="notification-method"
                      type="radio"
                      onChange={() =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedGrade: 1,
                        }))
                      }
                      defaultChecked={true}
                      className="focus:red h-4 w-4 text-red red border-gray-300"
                    />
                    <label
                      htmlFor={1}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {contentRegion === 0 ? "2.1 Class" : "A"} 
                    </label>
                  </p>
                </div>
                <div key={2} className="flex items-center mt-16">
                  <input
                    id={2}
                    name="notification-method"
                    type="radio"
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        selectedGrade: 2,
                      }))
                    }
                    defaultChecked={false}
                    className="focus:bg-primary h-4 w-4 checked:bg-primary  border-gray-300"
                  />
                  <label
                    htmlFor={2}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {contentRegion === 0 ? "First Class" : "B"}
                  </label>
                </div>
              </div>
              <div className="w-full lg:w-3/4 h-12 mb-20">
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".pdf,image/*"
                  capture="filesystem"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button className="mt-9" onClick={handleButtonClick}>
                  <img className="mr-3" src={UploadIcon} alt="uploadIcon" />
                  <p className="text-center text-14 xl:text-body-large">
                    Upload proof of your grades
                  </p>
                </Button>
                <div className="mt-10 px-6">
                  <div className="flex flex-row justify-between items-center mt-6">
                    <p className="text-darkest font-normal text-16 md:text-body-large">
                      {state.selectedFile?.name}
                    </p>
                    {state.selectedFile !== null && (
                      <img
                        className="cursor-pointer"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            selectedFile: null,
                          }))
                        }
                        src={RemoveIcon}
                        alt="remove"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-20 pb-0">
            <div className="flex flex-col items-center md:flex-row justify-center md:px-20">
              <div className="w-10/12 md:w-80  custom2:w-472">
                <div className="flex items-baseline mb-30">
                  <label for="condBox" className="text-18 condContainer ">
                    I agree to the Terms and Conditions, Privacy Policy and
                    Cookies Policy.{" "}
                    <input
                      id="condBox"
                      type="checkbox"
                      onChange={() => {
                        setState((prev) => ({
                          ...prev,
                          check: !state.check,
                        }));
                      }}
                      value={state.check}
                      className="default:ring-2 text-primary mr-3 checkBoxOne"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                {state.module.length === 0 && user?.author === null ? (
                  <Button filled onClick={() => handleCreateContentCreator()}>
                    <p className="text-center text-14 xl:text-body-large">
                      Continue
                    </p>
                  </Button>
                ) : state.module.length === 0 && user.author !== null ? (
                  <Button filled onClick={() => handleCreateContentCreator()}>
                    <p className="text-center text-14 xl:text-body-large">
                      Continue
                    </p>
                  </Button>
                ) : state.module.length !== 0 && user.author !== null ? (
                  <Button filled onClick={() => handleCreateContentCreator()}>
                    <p className="text-center text-14 xl:text-body-large">
                      Continue
                    </p>
                  </Button>
                ) : state.module.length !== 0 && user.author === null ? (
                  <Button filled onClick={() => handleCreateContentCreator()}>
                    <p className="text-center text-14 xl:text-body-large">
                      Continue
                    </p>
                  </Button>
                ) : null}

                {/* <p className="text-15 mt-30">
                  Join our{" "}
                  <a className="text-primary" href="#">
                    Slack Community
                  </a>{" "}
                  of Content Creators to Learn from Others and Share your
                  Experience
                </p> */}
              </div>
            </div>
          </div>
          {/* msg icon sec  */}
          <div className="chatSec absolute bottom-2/4 right-8">
            <a href="#">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_953_6327)">
                  <circle cx="40" cy="36" r="30" fill="white" />
                </g>
                <g clip-path="url(#clip0_953_6327)">
                  <path
                    d="M42.8125 26.625H31.5625C31.045 26.625 30.625 27.045 30.625 27.5625C30.625 28.08 31.045 28.5 31.5625 28.5H42.8125C43.33 28.5 43.75 28.08 43.75 27.5625C43.75 27.045 43.33 26.625 42.8125 26.625Z"
                    fill="#CC6828"
                  />
                  <path
                    d="M39.0625 30.375H31.5625C31.045 30.375 30.625 30.795 30.625 31.3125C30.625 31.83 31.045 32.25 31.5625 32.25H39.0625C39.58 32.25 40 31.83 40 31.3125C40 30.795 39.58 30.375 39.0625 30.375Z"
                    fill="#CC6828"
                  />
                  <path
                    d="M45.625 21H28.75C26.6819 21 25 22.6819 25 24.75V43.5C25 43.8638 25.21 44.1956 25.54 44.3494C25.6656 44.4075 25.8025 44.4375 25.9375 44.4375C26.1531 44.4375 26.3669 44.3625 26.5375 44.22L31.9019 39.75H45.625C47.6931 39.75 49.375 38.0681 49.375 36V24.75C49.375 22.6819 47.6931 21 45.625 21ZM47.5 36C47.5 37.0331 46.66 37.875 45.625 37.875H31.5625C31.3431 37.875 31.1312 37.9519 30.9625 38.0925L26.875 41.4994V24.75C26.875 23.7169 27.715 22.875 28.75 22.875H45.625C46.66 22.875 47.5 23.7169 47.5 24.75V36Z"
                    fill="#CC6828"
                  />
                  <path
                    d="M51.25 28.5C50.7325 28.5 50.3125 28.92 50.3125 29.4375C50.3125 29.955 50.7325 30.375 51.25 30.375C52.285 30.375 53.125 31.2169 53.125 32.25V48.1106L49.96 45.5794C49.795 45.4481 49.5869 45.375 49.375 45.375H36.25C35.215 45.375 34.375 44.5331 34.375 43.5V42.5625C34.375 42.045 33.955 41.625 33.4375 41.625C32.92 41.625 32.5 42.045 32.5 42.5625V43.5C32.5 45.5681 34.1819 47.25 36.25 47.25H49.045L53.4756 50.7956C53.6462 50.9306 53.8544 51 54.0625 51C54.1994 51 54.3381 50.97 54.4694 50.9081C54.7938 50.7506 55 50.4225 55 50.0625V32.25C55 30.1819 53.3181 28.5 51.25 28.5Z"
                    fill="#CC6828"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_953_6327"
                    x="0"
                    y="0"
                    width="80"
                    height="80"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_953_6327"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_953_6327"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_953_6327">
                    <rect
                      width="30"
                      height="30"
                      fill="white"
                      transform="translate(25 21)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>

          {/* msg icon sec // */}
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_detail: userData(state),
});
const mapDispatchToProps = (dispatch) => ({
  // getUserCSRF: () => dispatch(getUserCSRF()),
  getUserInfo: () => dispatch(getUserInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
