import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import Button from '../../components/EmptyButton'
import SignupImage3 from "../../assets/images/signupImage3.webp";
import Stepper from "../../components/SignUpStepper"
import Select from "../../components/Select";
import Modal from '../../components/SignUpModal/StepTwoModal'
import {useHistory} from "react-router-dom";
import {GetUniversities} from "../../services";
import {updateUser} from "../../redux/actions";
import { getUser } from '../../helpers';

const SignUpStepTwo = (props) => {
    const {updateUser} = props;
    const history = useHistory()
    const states = props.location.state;
    const user = JSON.parse(getUser())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [universitiesList, setUniversitiesList] = useState([])
    useEffect(() => {
        GetUniversities().then((res) => {
            if (res.data.length > 0) {
                let universities = []
                res.data.map((item) => {
                    universities.push({
                        value: item.name,
                        label: item.name,
                        item: item,
                    })
                })
                setUniversitiesList(universities);
            }
        })
    }, [])
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflowY = "hidden"
            document.body.style.position = "fixed"
        } else {
            document.body.style.overflowY = "unset"
            document.body.style.position = "unset"
        }
    }, [isModalOpen])
    const handleSelected = (selectedOption) => {
        setSelectedUniversity(selectedOption);
        let obj = {
            universities_ids: [selectedOption.item.id],
            username: user.username
        }
        updateUser(obj)
    }
    return (
        <div className='bg-white bg-main-page-back w-full bg-no-repeat bg-cover'>
            <Stepper step={2} exit={() => history.push('/')} totalSteps={3}/>
            <Modal
                isOpen={isModalOpen}
                buttonOneText='Notify us'
                onClickFirstButton={() => setIsModalOpen(false)}
                onClickSecondButton={() => setIsModalOpen(false)}
                closeModal={() => setIsModalOpen(false)}
                title='Where are you studying?'/>
            <div className="px-4 md:px-36 custom2:px-0">

                <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">Which
                    university are you studying in?</p>
                <div className="pb-11 flex flex-col justify-center items-center">
                    <div className="w-full md:w-472">
                        <Select
                            placeholder="Add university"
                            values={universitiesList}
                            handleSelected={handleSelected}
                        />
                    </div>
                    {/* <p className="font-normal text-body-large text-center">You cannot find your university?  */}
                    {/* <a
                        className="text-primary underline cursor-pointer" onClick={() => setIsModalOpen(true)}>Notify
                        us</a> */}
                    {/* </p> */}
                    <div className='w-full pt-4 flex justify-center mb-20 mt-12'>
                        <div className='w-full md:px-0 md:w-40 h-51'>
                            <Button onClick={() => history.push({
                                pathname: "/signup/step-three",
                                state: {
                                    selectedUniversity, states
                                }
                            })} disabled={!selectedUniversity} text='Continue' filled/>
                        </div>
                    </div>
                    <img className="hidden md:block" src={SignupImage3} alt="image"/>
                </div>

            </div>
        </div>

    )
}
const mapDispatchToProps = dispatch => ({
    updateUser: body => dispatch(updateUser(body))
})
export default connect(null, mapDispatchToProps)(SignUpStepTwo)
