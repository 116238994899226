import React, { useEffect, useState } from "react";

import { useHistory, Link } from "react-router-dom";
import Container from "../../components/container";
import LazyImage from "../../components/LazyImage";
import Button from "../../components/EmptyButton";
import Modal from "react-responsive-modal";
import { Field, Form, Formik } from "formik";
import CreatorImage from "../../assets/images/creator-image.webp";
import { getApiKey } from "../../helpers";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";
import Input from "../../components/Input/InputComponent";
import * as Yup from "yup";
import { createErrorAlert, userLogin, userSignUp } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";

const BecomeContentCreator = ({ userSignUp, userLogin }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loginModal: false,
    signUpModal: false,
    checkbox: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [])

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });
  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const handleStepOne = () => {
    if (getApiKey()) {
      history.push("/content-creator/step-zero");
    } else {
      setState((prev) => ({
        ...prev,
        loginModal: true,
      }));
    }
  };

  const checked = (e) => {
    if (e === true) {
      setState((prev) => ({
        ...prev,
        checkbox: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        checkbox: false,
      }));
    }
  };

  const handleUserLogin = async (body) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    if (state.checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };

  return (
    <div className="bg-white bg-creator-group mb-20 md:mb-0">
      <Container>
        <div className="text-center pt-10">
          <p className="font-bold text-h5 md:text-h4 custom:mt-14 custom:text-36">
            Join Our Team
          </p>
        </div>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 gap-5 mt-10 justify-center mb-10 md:pb-24">
          <div
            className="lg:col-span-6 flex flex-col items-center bg-white rounded-3xl"
            style={{ boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)" }}
          >
            <div className="mt-10 custom:mt-14">
              <LazyImage src={CreatorImage} alt="Creator-Image" />
            </div>
            <div className="text-center mt-10 custom:mt-14">
              <p className="font-bold text-h5 custom:text-h3">
                Become a Content Creator
              </p>
              <p className="text-darkGrey mt-5 px-2 md:px-20 custom:px-28 custom:mt-3 contCretP">
                You can sell study notes you already have, so you may not even need to create new content!
                <br/>
                <br/>
                If you were an excellent student and made valuable notes to prepare for your exams and received high grades,
                you can sell those study notes!
              </p>
            </div>
            <div className="mt-10 pb-10 custom:mt-12 ">
              <Button filled onClick={handleStepOne}>
                <p className="text-small-text px-10 custom:text-body-large custom:py-2">
                  SELL YOUR STUDY NOTES FOR CASH NOW
                </p>
              </Button>
            </div>
          </div>
          <div
            className="lg:col-span-6 py-6 px-4 bg-white rounded-3xl"
            style={{ boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)" }}
          >
            <div className="team-points">
              <p className="text-darkGrey text-leading-5 font-500 xl:leading-6 xl:text-16">
                Looking for a passive income stream without the daily grind?
                Becoming a content creator might be your ticket! Work from the
                comfort of your home, on your own terms. 🏡💻💰{" "}
              </p>
              <h3 className="font-bold text-h5 custom:text-h3">
                Unlock the perks of being a content creator:
              </h3>
              <ul className="mt-4 xl:mt-30" style={{ listStyle: "none" }}>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Passive Earnings: Make money round the clock, even while you
                    sleep! 💤💰
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Turn Passion into Profession: If your content shines, this
                    could be your livelihood!
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Be Your Own Boss: It's like running your own side hustle, or
                    even a full-time venture if you ace it.
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Hassle-Free Operation: Just focus on uploading content; we
                    handle payments, tech, and marketing.
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Work on Your Terms: No long-term commitments, enjoy the
                    freedom to work and rest as you please!
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Meaningful Impact: Empower struggling students by sharing
                    your expertise. Your content can be a game-changer, helping
                    them ace exams and secure top-notch graduate roles.
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Start monetising on your university degree even before
                    graduation to reap the rewards of your hard work. 💪💰
                  </span>
                </li>
              </ul>
              <h3 className="font-bold text-h5 custom:text-h3">Eligibility Criteria:</h3>
              <ul className="mt-4 xl:mt-30" style={{ listStyle: "none" }}>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Currently enrolled or have studied in a UK or US university.
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Open to all degree disciplines within the UK or US education
                    systems.
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Attained a minimum of 2.1 or B (in the American grading
                    system) in the specific study module you wish to sell
                    content for. For instance, if you've earned a B/2.1 in
                    contract law but a C/2.2 in criminal law, you can sell
                    content for contract law, but not for criminal law.{" "}
                  </span>
                </li>
                <li className="flex gap-2" style={{ marginBottom: "10px" }}>
                  <span className="flex-shrink-0 custom-bullet"></span>
                  <span className="text-darkGrey text-16 leading-5 xl:leading-6 xl:text-18">
                    Possess high-quality, original notes that can significantly
                    benefit fellow students. Please refrain from submitting
                    lecture handouts or content created by others, as this would
                    be a violation of intellectual property rights.
                  </span>
                </li>
              </ul>
              <p className="text-darkGrey text-leading-5 font-500 xl:leading-6 xl:text-16">
                Ready to contribute to the community of learners? Join us! 📚🌟
              </p>
            </div>
          </div>
        </div>
      </Container>

      {/* Login Modal */}
      <Modal
        open={state.loginModal}
        onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class1",
        }}
      >
        <div className="flex gap-6">
          <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">26K savvy SimpleStudying members</span> already
              empowered - and you're next
            </p>

            <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 "
            >
              Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                <p className="h-51">
                  Study Modules
                  <br />
                  All main areas of English law
                </p>
              </div> */}
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">366K+</p>
                <p>
                  Active Users
                  <br />
                  in 2023
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                <p className="h-51">
                  Hours Tutoring <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
          <div className="flex-basis-40">
            <Formik
              initialValues={{
                password: "",
                username: "",
              }}
              validationSchema={validateSchema}
              onSubmit={(values) => handleUserLogin(values)}
            >
              {({ isValid, handleSubmit, values }) => (
                <Form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="loginInputMain flex flex-col text-darkest items-center">
                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                        Sign in to{" "}
                        <span className="text-yellowish">SimpleStudying</span>
                      </p>
                      <div className="w-full mb-3 custom2::mb-9 ">
                        <p className="mb-8">Username or Email</p>
                        <Field
                          name="username"
                          as={Input}
                          autoComplete="off"
                          placeholder="Enter your username or email"
                          type="text"
                        />
                      </div>
                      <div className="w-full  mb-3 custom2:mb-9">
                        <p className="mb-8 ">Password</p>
                        <Field
                          name="password"
                          as={Input}
                          placeholder="Enter your password"
                          type={state.showPassword ? "text" : "password"}
                          setShowPassword={setState}
                          showPassword={state.showPassword}
                          inputType="password"
                          autoComplete="off"
                        />
                      </div>
                      <Link
                        className="mb-8 forgetText "
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            loginModal: false,
                          }));
                          history.push("/forget-password");
                        }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h:34 md:h-42 lg:h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Log in"
                          filled
                          className="buttonClass"
                          disabled={
                            values.username === "" ||
                            values.password === "" ||
                            !isValid
                          }
                          loading={state.loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="loginBottomText font-normal text-darkest font-body-large text-center">
              You don't have an account yet?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: false,
                    signUpModal: true,
                  }));
                }}
              >
                {"  "}Sign up
              </a>
            </p>
          </div>
        </div>
      </Modal>

      {/* Sign up Modal */}
      <Modal
        open={state.signUpModal}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            signUpModal: false,
          }))
        }
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class1",
        }}
      >
        <div className="flex gap-6">
          <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">26K savvy SimpleStudying members</span> already
              empowered - and you're next
            </p>

            <p className=" text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <p className="text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36 "
            >
              Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                <p className="h-51">
                  Study Modules
                  <br />
                  All main areas of English law
                </p>
              </div> */}
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">366K+</p>
                <p>
                  Active Users
                  <br />
                  in 2023
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                <p className="h-51">
                  Hours Tutoring <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
          <div className="flex-basis-40">
            <Formik
              initialValues={{
                password1: "",
                username: "",
                email: "",
                password2: "",
              }}
              validationSchema={validateSchemaSignup}
              onSubmit={(values) => onSignUp(values)}
            >
              {({ isValid, handleSubmit, values }) => (
                <Form>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col text-darkest items-center">
                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                        Sign up to{" "}
                        <span className="text-yellowish">SimpleStudying</span>
                      </p>
                      <div className="w-full mb-3 md:mb-9 ">
                        <p className="mb-8">Email</p>
                        <Field
                          name="email"
                          as={Input}
                          placeholder="Enter your email"
                          type="text"
                        />
                      </div>

                      <div className="w-full mb-3 md:mb-9 ">
                        <p className="mb-8">Username</p>
                        <Field
                          name="username"
                          as={Input}
                          placeholder="Enter your username"
                          type="text"
                        />
                      </div>

                      <div className="w-full ">
                        <p className="mb-8">Password</p>
                        <Field
                          name="password1"
                          as={Input}
                          placeholder="Enter your password"
                          type={state.showPassword ? "text" : "password"}
                          setShowPassword={setState}
                          showPassword={state.showPassword}
                          inputType="password"
                        />
                        <p className="text-small text-darkGrey2 my-8">
                          6+ characters, 1 capital letter{" "}
                        </p>
                      </div>
                      <div className="w-full mb-10 md:mb-14">
                        <p className="mb-8">Confirm password</p>
                        <Field
                          name="password2"
                          as={Input}
                          placeholder="Enter your confirm password"
                          type={state.showConfirmPassword ? "text" : "password"}
                          setShowPassword={setState}
                          showPassword={state.showConfirmPassword}
                          inputType="password"
                        />
                      </div>
                      <div
                        className="w-full mb-10 md:mb-14"
                        style={{ display: "flex" }}
                      >
                        <label className="condContainer">
                          {" "}
                          I agree to{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/terms-conditions");
                            }}
                          >
                            Terms and Conditions
                          </a>
                          ,{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/privacy-policy");
                            }}
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/cookies-policy");
                            }}
                          >
                            Cookies Policy
                          </a>{" "}
                          of Simple Studying{" "}
                          <input
                            id="condBox"
                            onClick={(e) => checked(e.target.checked)}
                            type="checkbox"
                            className="default:ring-2 text-primary mr-3 checkBoxOne"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Sign up for free"
                          filled
                          className="buttonClass"
                          disabled={
                            values.username === "" ||
                            values.password1 === "" ||
                            values.password2 === "" ||
                            values.email === "" ||
                            !isValid ||
                            values.checked === "false"
                          }
                          loading={state.loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="font-normal text-darkest font-body-large text-center">
              Already have an account?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: true,
                    signUpModal: false,
                  }));
                }}
              >
                {"  "}Log in
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomeContentCreator);
